import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private sessionService: SessionService) {}

  redirectAfterLogin(): any[] {
    if (this.sessionService.user.last_used_tenant_id) {
      return this.getLastUsedTenantRoute();
    } else {
      return this.getFirstTenantOrErrorRoute();
    }
  }

  private getLastUsedTenantRoute(): string[] {
    const url = ['/pdc'];
    url.push(this.sessionService.user.last_used_tenant_id.toString());
    return url;
  }

  private getFirstTenantOrErrorRoute(): any[] {
    const firstTenant = this.sessionService.user.tenants[0];

    if (firstTenant && firstTenant.permissions.length > 0) {
      return ['/pdc', firstTenant.id];
    }
    return ['/missing-configuration', { reason: 'pdc' }];
  }
}
