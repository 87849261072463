import { Inject, Injectable } from '@angular/core';
import { from } from 'rxjs';
import userflow from 'userflow.js';
import { SessionService } from './session.service';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';

@Injectable({
  providedIn: 'root'
})
export class UserflowService {

  constructor(
    private sessionService: SessionService,
    @Inject(ENVIRONMENT_CONFIG) private environment: any
  ) {
    this.init();
  }

  private init() {
    userflow.init(this.environment.userflow.token);
    this.identify();
    userflow.endAll();
  }

  private identify() {
    userflow.identify(this.sessionService.user.email, {
      name: this.sessionService.user.name,
      email: this.sessionService.user.email,
      signed_up_at: this.sessionService.user.created_at.toISOString() as any
    });
  }

  start() {
    return from(userflow.start(this.environment.userflow.onboarding_flow_id));
  }

  reset() {
    userflow.reset();
  }
}
