import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormlyModule } from '@ngx-formly/core';
import { OnboardingStatus, OnboardingUseCase } from '@parashift/shared/models';
import { CurrentUserService } from '@parashift/shared/services';
import { OnboardingUseCaseForm } from 'components/onboarding/onboarding-use-case-modal/onboarding-use-case.form';

@Component({
  selector: 'pdc-onboarding-use-case-modal',
  templateUrl: './onboarding-use-case-modal.component.html',
  styleUrls: ['./onboarding-use-case-modal.component.scss'],
  standalone: true,
  imports: [FormlyModule]
})
export class OnboardingUseCaseModalComponent implements OnInit {

  onboardingUseCase: OnboardingUseCase;
  onboardingUseCaseForm: OnboardingUseCaseForm;

  private tenant_id; // tenant_id is obtained from modal's initialState

  constructor(
    private bsModalRef: BsModalRef,
    private currentUserService: CurrentUserService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.initModelAndForm();
  }

  initModelAndForm() {
    this.onboardingUseCase = {
      evaluation_for: '',
      number_of_documents: '',
      number_of_use_cases: '',
      priority: ''
    };
    this.onboardingUseCaseForm = new OnboardingUseCaseForm(this.onboardingUseCase);
  }

  submit() {
    const onboarding = { ...this.onboardingUseCase, status: this.determineStatus() };
    this.currentUserService.saveTenantOnboarding(this.tenant_id, onboarding).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (onboarding.status !== OnboardingStatus.use_case) {
        this.continue();
      }
    });
  }

  continue() {
    this.bsModalRef.content.state = true;
    this.bsModalRef.hide();
  }

  private determineStatus(): OnboardingStatus {
    const model = this.onboardingUseCase;
    if (
      model.priority
      && model.number_of_use_cases
      && model.number_of_documents
      && model.evaluation_for
    ) {
      return OnboardingStatus.upload;
    }
    return OnboardingStatus.use_case;
  }
}
