import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CurrentUserService } from '@parashift/shared/services';
import { OnboardingStatus } from '@parashift/shared/models';
import { Tenant } from '@parashift/shared/models';
import { CurrentTenantRegister } from '@parashift/shared/services';
import { UserflowService } from '@parashift/shared/services';
import { localizePlural, OptionProps } from '@parashift/shared/utils';
import { ScriptLoaderComponent } from 'shared/components/script-loader/script-loader.component';

@Component({
  selector: 'pdc-onboarding-next-steps-modal',
  templateUrl: './onboarding-next-steps-modal.component.html',
  styleUrls: ['./onboarding-next-steps-modal.component.scss'],
  standalone: true,
  imports: [ScriptLoaderComponent, NgIf, ReactiveFormsModule, FormsModule]
})
export class OnboardingNextStepsModalComponent implements OnInit {
  hide_next_steps: boolean;
  trial_length = '';
  tenant: Tenant;

  private tenant_id; // tenant_id is obtained from modal's initialState

  constructor(
    private bsModalRef: BsModalRef,
    private currentTenantRegister: CurrentTenantRegister,
    private currentUserService: CurrentUserService,
    private userflowService: UserflowService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.initModelAndForm();
    this.getTenant();
  }

  initModelAndForm() {
    const onboarding = this.currentUserService.currentUser.getTenantOnboarding(this.tenant_id);
    this.hide_next_steps = onboarding.hide_next_steps;
  }

  private getTenant() {
    this.currentTenantRegister.getTenant(this.tenant_id).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.tenant = this.currentTenantRegister.tenant;
      this.trial_length = $localize `:@@shared_pdc.components.onboarding_next_steps_modal.trial_length:${this.tenant.expirationDays()}:itemCount: ${localizePlural(this.tenant.expirationDays() as OptionProps, { 0: $localize `:@@shared_pdc.components.onboarding_next_steps_modal.days:days`, 1: $localize `:@@shared_pdc.components.onboarding_next_steps_modal.day:day`, other: $localize `:@@shared_pdc.components.onboarding_next_steps_modal.days:days` })}:pluralDays: trial`;
    });
  }

  submit() {
    if (!this.shouldSave()) {
      this.continue();
      return;
    }
    const onboarding = { hide_next_steps: this.hide_next_steps, status: OnboardingStatus.complete };
    this.currentUserService.saveTenantOnboarding(this.tenant_id, onboarding).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.continue();
    });
  }

  continue() {
    this.bsModalRef.content.state = true;
    this.bsModalRef.hide();
  }

  private shouldSave() {
    const onboarding = this.currentUserService.currentUser.getTenantOnboarding(this.tenant_id);
    return onboarding.hide_next_steps !== this.hide_next_steps;
  }

  startProductTour() {
    this.submit();
    this.userflowService.start().subscribe();
  }
}
