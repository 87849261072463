import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BetaFeaturesService {

  private _initBetaFeatures$ = new BehaviorSubject<boolean>(false);
  readonly initBetaFeatures$ = this._initBetaFeatures$.asObservable();

  initBetaFeatures() {
    this._initBetaFeatures$.next(true);
  }
}
