import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgTemplateOutlet, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { OnboardingStatus } from '@parashift/shared/models';
import { BatchService, CurrentUserService,NotificationService } from '@parashift/shared/services';
import { AssetPipe, SafePipe } from '@parashift/shared/pipes';
import { DropAndUploadFileComponent } from 'shared/components/drop-and-upload-file/drop-and-upload-file.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'pdc-onboarding-upload-modal',
  templateUrl: './onboarding-upload-modal.component.html',
  styleUrls: ['./onboarding-upload-modal.component.scss'],
  standalone: true,
  imports: [DropAndUploadFileComponent, NgTemplateOutlet, NgIf, FaIconComponent, AssetPipe, SafePipe]
})
export class OnboardingUploadModalComponent {

  isFileUploaded = false;
  isLoading = false;
  sampleDocLabels = {
    label_1: $localize `:@@shared_pdc.components.onboarding_upload_modal.invoice:Invoice`,
    label_2: $localize `:@@shared_pdc.components.onboarding_upload_modal.correspondence:Correspondence`
  };

  private tenant_id; // tenant_id is obtained from modal's initialState
  private dropAndUploadFileComponent: DropAndUploadFileComponent;

  constructor(
    private bsModalRef: BsModalRef,
    private currentUserService: CurrentUserService,
    private batchService: BatchService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  initDropAndUploadComponent(component: DropAndUploadFileComponent) {
    component.setActiveStorageHost(environment.endpoints.individual_extraction);
    component.setAfterUploadCallback(blob => this.fileUploaded(blob));
    component.setAfterMultipleUploadsCallback(() => this.isLoading = false);
    this.dropAndUploadFileComponent = component;
    this.dropAndUploadFileComponent.fileProgress = [];
  }

  fileUploaded(blob) {
    this.isLoading = true;
    const sampleDocs = ['sample-invoice-v1-DE_CH.pdf', 'sample-correspondence-v1.pdf'];
    const notForTraining = sampleDocs.includes(blob.filename);

    this.batchService.createFromDirectUpload(
      blob,
      this.tenant_id,
      {
        validation_required: false,
        not_for_training: notForTraining
      },
      false
    ).subscribe(() => {
      this.notificationService.success({
        description: $localize `:@@shared_pdc.components.onboarding_upload_modal.upload_success_info:The document has been successfully uploaded.`,
        title: $localize `:@@shared_pdc.components.onboarding_upload_modal.upload_success:Upload success`
      });
      this.isFileUploaded = true;
      this.isLoading = false;
      this.submit();
    });
  }

  submit() {
    const onboarding = { status: this.determineStatus() };
    this.isLoading = true;
    this.currentUserService.saveTenantOnboarding(this.tenant_id, onboarding).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isLoading = false;
    });
  }

  continue() {
    this.bsModalRef.content.state = true;
    this.bsModalRef.hide();
  }

  uploadDocumentFromUrl(url: string) {
    if (this.isLoading) {
      return;
    }
    url = url.replace('jpg', 'pdf');
    this.isLoading = true;
    this.getDocumentBlob(url).subscribe(blob => {
      const file = new File([blob], this.getFileName(url), {
        type: 'application/pdf'
      });
      this.dropAndUploadFileComponent.fileProgress = [];
      this.dropAndUploadFileComponent.performUpload([file]);
    });
  }

  private getDocumentBlob(url: string): Observable<Blob> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  private getFileName(url: string): string {
    const split = url.split('/');
    return split[split.length - 1];
  }

  private determineStatus() {
    return this.isFileUploaded ? OnboardingStatus.complete : OnboardingStatus.upload;
  }
}
