<!-- Header -->
<div class="modal-header">

  <!-- Title -->
  <h6 class="modal-title" i18n="@@shared_pdc.components.onboarding_next_steps_modal.title">Well done! The document is processing. What do you want to do next?</h6>
  <!-- Title end -->

</div>
<!-- Header end -->

<!-- Content -->
<div class="modal-body">
  <div class="row">
    <div class="col-sm-4">
      <label i18n="@@shared_pdc.components.onboarding_next_steps_modal.welcome_note">Welcome note from the Parashift team</label>
      <div class="video-container">
        <iframe class="video" src="https://www.youtube.com/embed/9FZtnDF5lEM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div class="col-sm-4">
      <label i18n="@@shared_pdc.components.onboarding_next_steps_modal.product_video">Product video</label>
      <div class="video-container">
        <iframe class="video" src="https://www.youtube.com/embed/fDkoZtimrR0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="d-flex justify-content-center align-items-center h-100 mt-3">
        <button type="button" class="btn btn-xs btn-dark" (click)="startProductTour()">
          <ng-container i18n="@@shared_pdc.components.onboarding_next_steps_modal.take_product_tour">Take Product Tour</ng-container>
          <br>
          <small i18n="@@shared_pdc.components.onboarding_next_steps_modal.step_by_step">Step by step</small>
        </button>
      </div>
    </div>
    <div class="col-sm-8 mt-4">
      <label i18n="@@shared_pdc.components.onboarding_next_steps_modal.talk_to_someone">Talk to a Parashiftian</label>
      <div>
        <!-- Start of Meetings Embed Script -->
        <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/leandro-vaglietti/meeting-request?embed=true"></div>
        <script-loader src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script-loader>
        <!-- End of Meetings Embed Script -->
      </div>
    </div>
    <div class="col-sm-4 mt-4">
      <div class="d-flex flex-column h-100">
        <label i18n="@@shared_pdc.components.onboarding_next_steps_modal.support_resources">Support & Integration Resources</label>
        <div>
          <a href="https://support.parashift.io/" target="_blank" i18n="@@shared_pdc.components.onboarding_next_steps_modal.user_documentation">User Documentation</a>
          <br>
          <!--        <a href="TBD" target="_blank">-->
          <!--          {{ 'shared_pdc.components.onboarding_next_steps_modal.api_integrations' }}-->
          <!--        </a>-->
          <!--        <br>-->
          <a href="https://docs.parashift.io/" target="_blank" i18n="@@shared_pdc.components.onboarding_next_steps_modal.api_documentation">API Documentation</a>
          <br>
          <a href="https://www.parashift.io/faqs/" target="_blank" i18n="@@shared_pdc.components.onboarding_next_steps_modal.product_qa">Product Q&A</a>
          <br>
          <a href="https://www.parashift.io/customer-commitment/" target="_blank" i18n="@@shared_pdc.components.onboarding_next_steps_modal.rickroll">Our customer commitment</a>
          <br>
          <a href="https://www.parashift.io/partners/" target="_blank" i18n="@@shared_pdc.components.onboarding_next_steps_modal.integration_partner">Find Integration & Consulting Partner</a>
          <br>
          <a href="https://www.parashift.io/professional-services/" target="_blank" i18n="@@shared_pdc.components.onboarding_next_steps_modal.integration_help">Get integration help</a>
        </div>
        <div class="d-flex flex-column justify-content-end align-items-center h-100">
          <button type="button" class="btn btn-xs btn-success" (click)="submit()">
            <ng-container i18n="@@shared_pdc.components.onboarding_next_steps_modal.start_exploring">Start Exploring</ng-container>
            <br>
            <small i18n="@@shared_pdc.components.onboarding_next_steps_modal.parashift_platform">Parashift Platform</small>
          </button>
          <small *ngIf="tenant && tenant.isTrial() && tenant.expires_on" class="mt-1">
            {{ tenant.expirationDays() }} {{ trial_length }}
          </small>
        </div>
      </div>
    </div>
<!--    <div class="col-sm-2 mt-4 start-exploring">-->
<!--      <button type="button" class="btn btn-xs btn-success" (click)="submit()">-->
<!--        {{ 'shared_pdc.components.onboarding_next_steps_modal.start_exploring' }}-->
<!--        <br>-->
<!--        <small i18n="@@shared_pdc.components.onboarding_next_steps_modal.parashift_platform">Parashift Platform</small>-->
<!--      </button>-->
<!--      <small *ngIf="tenant && tenant.isTrial() && tenant.expires_on">-->
<!--        {{ tenant.expirationDays() }} {{ 'shared_pdc.components.onboarding_next_steps_modal.trial_length' { days: tenant.expirationDays() } }}-->
<!--      </small>-->
<!--    </div>-->
  </div>
</div>
<!-- Content end -->

<!-- Footer -->
<div class="modal-footer">
  <div class="form-check">
    <input type="checkbox"
           id="hide-next-steps"
           class="form-check-input pointer"
           [(ngModel)]="hide_next_steps">
    <label class="form-check-label pointer" for="hide-next-steps" i18n="@@shared_pdc.components.onboarding_next_steps_modal.dont_show_again">Don't show this again</label>
  </div>
</div>
<!-- Footer end -->
