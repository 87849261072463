import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OnboardingCompany, OnboardingStatus } from '@parashift/shared/models';
import { CurrentUserService } from '@parashift/shared/services';
import { ListFilter } from '@parashift/shared/models';
import { OnboardingCompanyForm } from 'components/onboarding/onboarding-company-modal/onboarding-company.form';

@Component({
  selector: 'pdc-onboarding-company-modal',
  templateUrl: './onboarding-company-modal.component.html',
  styleUrls: ['./onboarding-company-modal.component.scss'],
  standalone: true,
  imports: [NgIf, FormlyModule]
})
export class OnboardingCompanyModalComponent implements OnInit {

  onboardingCompany: OnboardingCompany = {
    company_name: '',
    phone_number: '',
    company_type: '',
    company_role: '',
    dont_call_me: false,
    validation: false
  };
  onboardingCompanyForm: OnboardingCompanyForm;
  listFilter = new ListFilter({ include: 'addresses' });

  private tenant_id; // tenant_id is obtained from modal's initialState

  constructor(
    private bsModalRef: BsModalRef,
    private currentUserService: CurrentUserService,
    // private companyService: CrmCompanyService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.initModelAndForm();
  }

  initModelAndForm() {
    // For now we disable onboarding because it is broken due to removed crm endpoint.

    // this.companyService.findAll(this.listFilter, this.tenant_id).subscribe(
    //   (crmCompanies) => {
    //     const company = crmCompanies.getModels()[0];

    //     const company_name = company.name || '';
    //     let phone_number = '';

    //     if (company && company.addresses) {
    //       const company_address = company.addresses[0] as CrmAddress;
    //       phone_number = company_address.phone_number || '';
    //     }

    //     this.onboardingCompany = {
    //       ...this.onboardingCompany,
    //       company_name,
    //       phone_number,
    //     };

    //     this.onboardingCompanyForm = new OnboardingCompanyForm(this.onboardingCompany);
    //   }
    // );
  }

  submit() {
    const onboarding = { ...this.onboardingCompany, status: this.determineStatus() };
    this.onboardingCompanyForm.model = {...this.onboardingCompanyForm.model, validation: true};

    this.currentUserService.saveTenantOnboarding(this.tenant_id, onboarding).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (onboarding.status !== OnboardingStatus.company) {
        this.continue();
      }
    });
  }

  continue() {
    this.bsModalRef.content.state = true;
    this.bsModalRef.hide();
  }

  private determineStatus(): OnboardingStatus {
    // check required fields first
    if (!(this.onboardingCompany.company_name && this.onboardingCompany.company_type && this.onboardingCompany.company_role)) {
      return OnboardingStatus.company;
    }
    // if "dont call me" true then phone number is optional
    if (this.onboardingCompany.dont_call_me) {
      return OnboardingStatus.use_case;
    }
    // if "dont call me" false then phone number is required
    if (this.onboardingCompany.phone_number) {
      return OnboardingStatus.use_case;
    }
    return OnboardingStatus.company;
  }
}
