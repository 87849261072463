<!-- Header -->
<div class="modal-header">

  <!-- Title -->
  <h6 class="modal-title" i18n="@@shared_pdc.components.onboarding_use_case_modal.title">Tell us about your use case</h6>
  <!-- Title end -->

</div>
<!-- Header end -->

<!-- Content -->
<div class="modal-body">
  <formly-form [form]="onboardingUseCaseForm.form"
               [fields]="onboardingUseCaseForm.fields"
               [model]="onboardingUseCaseForm.model"
               [options]="onboardingUseCaseForm.options"></formly-form>
</div>
<!-- Content end -->

<!-- Footer -->
<div class="modal-footer">
  <button type="button" class="btn btn-xs btn-success" (click)="submit()" i18n="@@common.continue">Continue</button>
</div>
<!-- Footer end -->
