import { Inject, Injectable } from '@angular/core';
import { Me } from '@parashift/shared/models';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';

@Injectable({
  providedIn: 'root'
})
export class ResetUserSettingsService {

  constructor(@Inject(ENVIRONMENT_CONFIG) private environment: any) {}

  runJobs(me: Me) {
    me = this.cleanUpResetSettings(me);

    if (this.environment.reset.filters) {
      me = this.resetFilters(me);
    }

    return me;
  }

  private resetFilters(me: Me): Me {
    if (!me.settings?.reset?.filters?.done && me.settings?.['filters']) {
      me.settings['filters'] = {};
    }

    if (!me.settings) {
      me.settings = {};
    }

    if (!me.settings.reset) {
      me.settings.reset = {};
    }

    if (!me.settings.reset.filters) {
      me.settings.reset.filters = { done: true, version: this.environment.version };
    }

    return me;
  }

  private cleanUpResetSettings(me: Me): Me {
    if (me.settings?.reset?.filters?.version && me.settings?.reset?.filters?.version !== this.environment.version) {
      delete me.settings.reset.filters;
    }

    return me;
  }
}
