import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BasicForm } from 'shared/components/forms/basic.form';
import { EvaluationSubject, NumberOfDocuments, NumberOfUseCases, OnboardingUseCase, TimePriority } from '@parashift/shared/models';
import { getSelectboxOptionsFromConstants } from '@parashift/shared/utils';

export class OnboardingUseCaseForm extends BasicForm {
  constructor(public model: OnboardingUseCase, public options: FormlyFormOptions = {}) {
    super(model, options);
    this.options.formState = {
      ...this.options.formState,
      model: this.model
    };
  }

  initFields() {
    this.fields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-6',
            key: 'evaluation_for',
            type: 'select',
            props: {
              label: $localize `:@@shared_pdc.components.onboarding_use_case_modal.evaluation_for:I evaluate Parashift for`,
              options: getSelectboxOptionsFromConstants(EvaluationSubject),
              required: true
            }
          },
          {
            className: 'col-sm-6',
            key: 'number_of_use_cases',
            type: 'select',
            props: {
              label: $localize `:@@shared_pdc.components.onboarding_use_case_modal.number_of_use_cases:Potential no. of use cases`,
              options: getSelectboxOptionsFromConstants(NumberOfUseCases, true, 'shared.filter_column.not_set', '', false),
              required: true
            }
          },
          {
            className: 'col-sm-6',
            key: 'number_of_documents',
            type: 'select',
            props: {
              label: $localize `:@@shared_pdc.components.onboarding_use_case_modal.number_of_documents:Company-wide number of documents p. a.`,
              options: getSelectboxOptionsFromConstants(NumberOfDocuments),
              required: true
            }
          },
          {
            className: 'col-sm-6',
            key: 'priority',
            type: 'select',
            props: {
              label: $localize `:@@shared_pdc.components.onboarding_use_case_modal.priority:How fast do you need solution?`,
              options: getSelectboxOptionsFromConstants(TimePriority),
              required: true
            }
          }
        ]
      }
    ] as FormlyFieldConfig[];
  }
}
