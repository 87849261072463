import { AfterViewInit, Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'script-loader',
  template: '',
  standalone: true
})
export class ScriptLoaderComponent implements AfterViewInit {
  @Input() src: string;

  constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const script = this.renderer.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', this.src);

    const root = this.elementRef.nativeElement;

    this.renderer.insertBefore(root.parentElement, script, root);
    this.renderer.removeChild(root.parentElement, root);
  }
}
