import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BetaFeaturesService, CurrentUserService, Languages, LanguageService, MeService, RedirectService, ResetUserSettingsService, SessionService, UserService } from '@parashift/shared/services';
import { Me, TokenCodeExchange, TokenResponse, ListFilter } from '@parashift/shared/models';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private redirect_after_login: string;

  constructor(
    private meService: MeService,
    private userService: UserService,
    private sessionService: SessionService,
    private redirectService: RedirectService,
    private resetUserSettingsService: ResetUserSettingsService,
    private currentUserService: CurrentUserService,
    private betaFeaturesService: BetaFeaturesService,
    private languageService: LanguageService,
    private localeService: BsLocaleService
  ) {}

  get isAuthenticated(): boolean {
    const now = Number(Date.now().valueOf() / 1000);
    const expiresAt = Number(this.sessionService.expires_at);

    if (typeof expiresAt !== 'undefined') {
      const authenticated = now < expiresAt;
      !authenticated && this.sessionService.destroyTokens();
      return authenticated;
    }

    return false;
  }

  login(error = false) {
    this.sessionService.destroyTokens();
    const redirect_on_error = this.redirect_after_login === 'undefined' || !this.redirect_after_login ? environment.host : this.redirect_after_login;
    const redirect_to = error ? redirect_on_error : window.location.href;
    const url = environment.id_node + `/users/sign_in?app_id=${environment.app_id}&redirect_to=${redirect_to}`;
    window.location.href = url;
  }

  handleAuthentication(): Promise<Me> {
    let promise: Promise<Me>;

    const redirect = this.getUrlParameter('redirect_to') || undefined;

    if (redirect) {
      this.redirect_after_login = decodeURI(redirect);
    }

    const code_verifier = this.getUrlParameter('code_verifier');
    const user_id = this.getUrlParameter('user_id');

    if (user_id && code_verifier) {
      const tokenCodeExchange: TokenCodeExchange = { id: user_id, code_verifier };

      promise = lastValueFrom(of(null).pipe(
        switchMap(() => this.userService.getToken(tokenCodeExchange).pipe(
          tap((tokenResponse: TokenResponse) => {
            this.sessionService.refreshGlobalToken(tokenResponse.token);
            this.sessionService.setLoginTimestamp();
          }),
          catchError(error => {
            this.login(true);
            this.sessionService.destroySession();
            return throwError(() => error);
          })
        )),
        switchMap(() => this.getInitialUser())
      ));

    } else {
      promise = lastValueFrom(this.getInitialUser());
    }

    return promise;
  }

  handleRedirect(): Observable<any[] | string> {
    const redirect = this.checkRedirectAfterLogin() || this.redirectService.redirectAfterLogin();
    return of(redirect);
  }

  logout() {
    this.sessionService.destroySession();
    const url = environment.id_node + '/users/sign_out';
    window.location.href = url;
  }

  private getInitialUser(): Observable<Me> {
    const listFilter = new ListFilter({ include: 'roles,avatar_attachment' });
    return this.meService.getInitialUser(listFilter).pipe(
      tap((me: Me) => this.setInitialUserSettings(me)),
      tap((me: Me) => this.sessionService.refreshGlobalToken(me.refresh_token)),
      tap(() => this.betaFeaturesService.initBetaFeatures())
    );
  }

  private async setInitialUserSettings(me: Me) {
    me = this.resetUserSettingsService.runJobs(me);
    this.sessionService.user = me.plainModel as Me;
    this.sessionService.user_id = me.id;
    this.currentUserService.set(me);
    const bsLang = await this.languageService.setLanguage(this.sessionService.user.language as Languages, false);
    this.localeService.use(bsLang);
    // this.sessionService.refreshToken(me.refresh_token);

    if (me.plainModel.settings.filters && this.sessionService.login_diff && this.sessionService.login_diff > 5000) {
      this.sessionService.filters = me.plainModel.settings.filters;
    } else {
      this.sessionService.filters = {};
    }

    this.sessionService.layout = me.plainModel.settings.layout ?? {};
    this.sessionService.user_navigation = me.plainModel.settings.user_navigation ?? {};
    this.sessionService.show_sticky_fields = me.plainModel.settings.show_sticky_fields ?? false;
    this.sessionService.views = me.plainModel.settings.views ?? {};
    this.sessionService.global_page_size = me.plainModel.settings.global_page_size ?? environment.list_default_page_size;
    this.sessionService.stay_with_document = me.plainModel.settings.stay_with_document ?? false;
    this.sessionService.show_complete_validation_popup = me.plainModel.settings.show_complete_validation_popup ?? true;
    this.sessionService.user_filters = me.plainModel.settings.user_filters ?? {};
  }

  private getUrlParameter(param: string): string {
    param = param.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + param + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  private checkRedirectAfterLogin(): string {
    if (this.redirect_after_login && this.redirect_after_login !== 'undefined') {
      const url = new URL(this.redirect_after_login);
      this.redirect_after_login = undefined;
      const noRedirect = [
        '/home',
        '/session/callback',
        '/missing-configuration',
        '/'
      ];

      if (!noRedirect.includes(url.pathname)) {
        return url.pathname + url.search;
      }
    }

    return undefined;
  }
}
