import { Component } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'pdc-onboarding-contact-us-modal',
  templateUrl: './onboarding-contact-us-modal.component.html',
  styleUrls: ['./onboarding-contact-us-modal.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class OnboardingContactUsModalComponent {
  trial: boolean;
}
