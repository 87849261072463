<!-- Header -->
<div class="modal-header">

  <!-- Title -->
  <h6 class="modal-title" i18n="@@shared_pdc.components.onboarding_contact_us_modal.contact_us">Contact us</h6>
  <!-- Title end -->

</div>
<!-- Header end -->

<!-- Content -->
<div class="modal-body">
  <span *ngIf="trial" i18n="@@shared_pdc.components.onboarding_contact_us_modal.tenant_trial_expired">Your trial has expired, please contact us on <a class="mailto" href="mailto:sales@parashift.io">sales&#64;parashift.io</a>.</span>
  <span *ngIf="!trial" i18n="@@shared_pdc.components.onboarding_contact_us_modal.tenant_expired">Your tenant expired, please contact us on <a class="mailto" href="mailto:sales@parashift.io">sales&#64;parashift.io</a>.</span>
</div>
<!-- Content end -->

<!-- Footer -->
<div class="modal-footer"></div>
<!-- Footer end -->
