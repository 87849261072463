import { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

// FD: TODO: Is this pipe & asset() still necessary?
@Pipe({
  name: 'asset',
  standalone: true
})
export class AssetPipe implements PipeTransform {
  transform(url: string): string {
    return url;
    // return asset(url);
  }
}
