import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router, Event } from '@angular/router';
import { defer, EMPTY, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, expand, filter, map, takeUntil } from 'rxjs/operators';
import { OnboardingStatus, TenantOnboarding } from '@parashift/shared/models';
import { CurrentTenantRegister, CurrentUserService, ProductRoutingService, SessionService } from '@parashift/shared/services';
import { ModalService } from 'shared/components/modals/modal.service';
import { OnboardingCompanyModalComponent } from 'components/onboarding/onboarding-company-modal/onboarding-company-modal.component';
import { OnboardingUseCaseModalComponent } from 'components/onboarding/onboarding-use-case-modal/onboarding-use-case-modal.component';
import { OnboardingUploadModalComponent } from 'components/onboarding/onboarding-upload-modal/onboarding-upload-modal.component';
import { OnboardingContactUsModalComponent } from 'components/onboarding/onboarding-contact-us-modal/onboarding-contact-us-modal.component';
import { OnboardingNextStepsModalComponent } from 'components/onboarding/onboarding-next-steps-modal/onboarding-next-steps-modal.component';

@Injectable({
  providedIn: 'root'
})
export class OnboardingWizardService {

  private cancel$ = new Subject<void>();
  private onboarding_tenant_id;
  private onboarding_tenant_phone;

  private disableIn = [
    'personal-settings'
  ];

  private nextStepsOpened = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private currentTenantRegister: CurrentTenantRegister,
    private currentUserService: CurrentUserService,
    private modalService: ModalService,
    private productRoutingService: ProductRoutingService,
    private sessionService: SessionService
  ) {}

  listenToRoutingChanges() {
    this.router.events
      .pipe(
        filter((event: Event): event is NavigationStart => event instanceof NavigationStart),
        distinctUntilChanged((x: NavigationStart, y: NavigationStart) => this.areProductAndTenantTheSame(x, y))
      ).subscribe(() => {
        this.stop();
      });

    this.productRoutingService.tenant_id$.subscribe(tenant_id => {
      if (this.isDisabledInCurrentRoute()) {
        this.stop();
      } else {
        this.start(tenant_id);
      }
    });
  }

  start(tenant_id, forceShowNextSteps = false) {
    if (!tenant_id || tenant_id === this.onboarding_tenant_id || !this.productRoutingService.isPdc()) {
      return;
    }
    this.currentTenantRegister.getTenant(tenant_id).subscribe(() => {
      if (this.isUserEmployee()) {
        return;
      }
      const tenant = this.currentTenantRegister.tenant;
      if (tenant.isExpired()) {
        this.showContactUsModal();
        return;
      }
      if (!tenant.isTrial() /*|| this.isOnboardingComplete(tenant_id)*/) {
        return;
      }
      this.onboarding_tenant_id = tenant_id;
      this.onboarding_tenant_phone = tenant.phone;

      const runWizardStage$ = defer(() => this.runWizardStage(tenant_id, forceShowNextSteps));
      runWizardStage$
        .pipe(
          expand(complete => complete ? EMPTY : runWizardStage$),
          takeUntil(this.cancel$)
        )
        .subscribe(complete => {
          if (complete) {
            this.onboarding_tenant_id = undefined;
          }
        });
    });
  }

  private stop() {
    this.modalService.hide();
    this.cancel$.next();
    this.onboarding_tenant_id = undefined;
    this.nextStepsOpened = false;
  }

  private runWizardStage(tenant_id, forceShowNextSteps = false) {
    const onboarding = this.currentUserService.currentUser.getTenantOnboarding(tenant_id);

    switch (onboarding.status) {
      case OnboardingStatus.company:
        return this.showModal(tenant_id, OnboardingCompanyModalComponent);
      case OnboardingStatus.use_case:
        return this.showModal(tenant_id, OnboardingUseCaseModalComponent);
      case OnboardingStatus.upload:
        return this.showModal(tenant_id, OnboardingUploadModalComponent);
      case OnboardingStatus.complete:
        return this.showNextStepsModal(tenant_id, forceShowNextSteps, onboarding).pipe(map(() => true));
    }
  }

  private showModal(tenant_id, component) {
    const config = { initialState: { tenant_id,  class: 'modal-lg onboarding-custom', phone_number: this.onboarding_tenant_phone } };

    // (KH) without setTimeout() this call sometimes shows backdrop with no modal
    // return this.modalService.customized(config, component).pipe(mapTo(false));

    return new Observable<boolean>(observer => {
      setTimeout(() => {
        this.modalService.customized(config, component).subscribe(() => {
          observer.next(false);
          observer.complete();
        });
      }, 100);
    });
  }

  private isUserEmployee() {
    return this.sessionService.userHasRole('support') || this.sessionService.userHasRole('sales');
  }

  private isOnboardingComplete(tenant_id) {
    return this.currentUserService.currentUser.getTenantOnboarding(tenant_id).status === OnboardingStatus.complete;
  }

  private showContactUsModal() {
    const initialState = { trial: this.currentTenantRegister.tenant.isTrial() };
    setTimeout(() => {
      this.modalService.customized({ initialState }, OnboardingContactUsModalComponent).subscribe();
    }, 100); // (KH) without setTimeout() this call sometimes shows backdrop with no modal
  }

  private showNextStepsModal(tenant_id, forceShowNextSteps: boolean, onboarding: TenantOnboarding) {
    if (!forceShowNextSteps && onboarding.hide_next_steps) {
      return of(false);
    }
    if (!forceShowNextSteps && this.nextStepsOpened) {
      return of(false);
    }
    this.nextStepsOpened = true;
    return this.showModal(tenant_id, OnboardingNextStepsModalComponent);
  }

  private areProductAndTenantTheSame(x: NavigationStart, y: NavigationStart): boolean {
    const left = this.getProductAndTenantId(x);
    const right = this.getProductAndTenantId(y);
    return left.product === right.product && left.tenant_id === right.tenant_id;
  }

  private getProductAndTenantId(nav: NavigationStart) {
    const split = nav.url.split('/').filter(x => !!x);
    const product = split[0];
    const tenant_id = split[1] ? Number(split[1]) : undefined;
    return { product, tenant_id };
  }

  private isDisabledInCurrentRoute() {
    const children = [...this.activatedRoute.snapshot.children];
    while (children.length > 0) {
      const child = children.pop();
      const url = child.url.toString();
      if (this.disableIn.some(route => url.includes(route))) {
        return true;
      }
      children.push(...child.children);
    }
    return false;
  }
}
