<!-- Header -->
<div class="modal-header">

  <!-- Title -->
  <h6 class="modal-title" i18n="@@shared_pdc.components.onboarding_company_modal.welcome">Welcome to Parashift</h6>
  <!-- Title end -->

</div>
<!-- Header end -->

<!-- Content -->
<div class="modal-body">
  <formly-form *ngIf="onboardingCompanyForm"
               [form]="onboardingCompanyForm.form"
               [fields]="onboardingCompanyForm.fields"
               [model]="onboardingCompanyForm.model"
               [options]="onboardingCompanyForm.options"></formly-form>
</div>
<!-- Content end -->

<!-- Footer -->
<div class="modal-footer">
  <button type="button" class="btn btn-xs btn-success" (click)="submit()" i18n="@@common.continue">Continue</button>
</div>
<!-- Footer end -->
