import { Injectable } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { SessionService } from './session.service';
import { CurrentUserService } from './current-user.service';

export enum Languages {
  en = 'en',
  de = 'de'
}

// FD: TODO: Check what needs to be done for bootstrap locales

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    private sessionService: SessionService,
    private currentUserService: CurrentUserService,
    private localeService: BsLocaleService
  ) {}

  async setLanguage(lang: Languages, saveSettings = true) {
    const bsLanguages = {
      en: 'engb',
      de: 'de'
    };

    this.sessionService.language = lang;
    this.localeService.use(bsLanguages[lang]);

    if (saveSettings) {
      this.currentUserService.saveSettings();
    }

    return bsLanguages[lang];
  }
}
