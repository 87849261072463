<!-- Header -->
<div class="modal-header">

  <!-- Title -->
  <h6 class="modal-title">
    <ng-container i18n="@@shared_pdc.components.onboarding_upload_modal.upload_first_document">Now, upload a first document</ng-container>
    <br>
    <small style="text-transform: none" i18n="@@shared_pdc.components.onboarding_upload_modal.choose_invoice_or_letter">Choose an invoice or a correspondence. Other documents are currently not activated in your tenant.</small>
  </h6>

  <!-- Title end -->

</div>
<!-- Header end -->

<!-- Content -->
<div class="modal-body">
  <app-drop-and-upload-file title="Add a file"
                            i18n-title="@@shared_pdc.components.onboarding_upload_modal.drop_and_upload.title"
                            instructions="Drag and drop files here or add it manually to continue"
                            i18n-instructions="@@shared_pdc.components.onboarding_upload_modal.drop_and_upload.instructions"
                            (init)="initDropAndUploadComponent($event)">
  </app-drop-and-upload-file>

  <div>
    <h6>
      <small i18n="@@shared_pdc.components.onboarding_upload_modal.pickup_sample_document">Or click on one of the documents below to choose our sample document</small>
    </h6>

    <div class="sample-docs d-flex flex-row mt-4">
      <ng-container *ngTemplateOutlet="sample_doc; context: {
        url: 'assets/documents/sample-invoice-v1-DE_CH.jpg' | asset,
        label: sampleDocLabels.label_1
      }"></ng-container>

      <ng-container *ngTemplateOutlet="sample_doc; context: {
        url: 'assets/documents/sample-correspondence-v1.jpg' | asset,
        label: sampleDocLabels.label_2
      }"></ng-container>
    </div>
  </div>
</div>
<!-- Content end -->

<!-- Footer -->
<div class="modal-footer">
  <button *ngIf="isFileUploaded" type="button" class="btn btn-xs btn-success" (click)="continue()" i18n="@@common.continue">Continue</button>
</div>
<!-- Footer end -->

<ng-template #sample_doc let-url="url" let-label="label">
  <div class="sample-doc">
    <img [src]="url | safe: 'resourceUrl'" [alt]="label">
    <div class="sample-doc-overlay" (click)="uploadDocumentFromUrl(url)">
      <fa-icon [icon]="['fas', 'upload']"></fa-icon>
      <div>{{ label }}</div>
    </div>
  </div>
</ng-template>
